import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Aboutus from "routes/about-us/aboutus";
import Layout from "routes/layout/layout";
import React, { lazy } from "react";
import AssetInformation from "routes/assetInformation/assetInformation";

const Blogs = lazy(() => import("routes/blogs/blogs"));
const Careers = lazy(() => import("routes/careers/careers"));
const CaseStudies = lazy(() => import("routes/case-studies/caseStudies"));
const CommercialFitness = lazy(
  () => import("routes/commercialFitness/commercialFitness")
);
const ComputerPeripherals = lazy(
  () => import("routes/computerPeripherals/computerPeripherals")
);
const ContactUs = lazy(() => import("routes/contact-us/contactus"));
const ElectricalAppliances = lazy(
  () => import("routes/industrialAppliances/electricalAppliances")
);
const FAQs = lazy(() => import("routes/faqs/faqs"));
const FoodAndBeverages = lazy(
  () => import("routes/foodAndBeverages/foodAndBeverages")
);
const FurniturePackage = lazy(
  () => import("routes/furniturePackages/furniturePackages")
);
const HomePage = lazy(() => import("routes/homePage/homePage"));

const NotFound = lazy(() => import("routes/not-found/notFound"));
const PrivacyPolicy = lazy(() => import("routes/privacyPolicy/PrivacyPolicy"));
const RecreationalSetups = lazy(
  () => import("routes/recreationalSetups/recreationalSetups")
);
const SingleBlog = lazy(() => import("routes/blogs/singleBlog"));
const TermsAndConditions = lazy(
  () => import("routes/termsAndCondition/TermsAndConditions")
);

function App() {
  const router = createBrowserRouter([
    {
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <HomePage />,
        },
        {
          path: "/furniture-packages",
          element: <FurniturePackage />,
        },
        {
          path: "/electronic-appliances",
          element: <ElectricalAppliances />,
        },
        {
          path: "/computer-peripherals",
          element: <ComputerPeripherals />,
        },
        {
          path: "/recreational-setups",
          element: <RecreationalSetups />,
        },
        {
          path: "/commercial-fitness",
          element: <CommercialFitness />,
        },
        {
          path: "/food-and-beverages",
          element: <FoodAndBeverages />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/terms-and-conditions",
          element: <TermsAndConditions />,
        },
        {
          path: "/contact-us",
          element: <ContactUs />,
        },
        {
          path: "/careers",
          element: <Careers />,
        },
        {
          path: "/about-us",
          element: <Aboutus />,
        },
        {
          path: "/faqs",
          element: <FAQs />,
        },
        {
          path: "/case-studies",
          element: <CaseStudies />,
        },
        {
          path: "/blogs",
          element: <Blogs />,
        },
        {
          path: "/blog/:id",
          element: <SingleBlog />,
        },
        {
          path: "/get_asset_information",
          element: <AssetInformation />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return (
    <React.Fragment>
      <Toaster />
      <RouterProvider router={router} />
    </React.Fragment>
  );
}

export default App;
